import { useModal } from '@kelvininc/react-ui-components';
import { ReactNode, useCallback, useEffect, useState } from 'react';

import { filter } from 'rxjs/operators';

import { Modal } from '../Modal';
import { IModalAlert } from '../types';

import { MODAL_CONFIG, modalController$ } from './config';
import {
	CloseModalEventPayload,
	EModalEvent,
	IModalConfig,
	ModalAlertConfigEventPayload,
	ModalConfigEventPayload,
	OpenModalEventPayload
} from './types';

export const ModalObserver = () => {
	const { isOpen, open, close } = useModal();

	const [Content, setContentComponent] = useState<ReactNode>();
	const [modalConfig, setModalConfig] = useState<IModalConfig>(MODAL_CONFIG);
	const [alertConf, setAlert] = useState<IModalAlert>();

	const closeModal = useCallback(
		(force = false) => {
			// Prevent closing modal
			if (!force && modalConfig.onClickClose?.() === false) {
				return;
			}

			close();
			setContentComponent(null);
			setAlert(undefined);
		},
		[close, modalConfig]
	);

	useEffect(() => {
		const subs = [
			modalController$
				.pipe(
					filter((data): data is OpenModalEventPayload => data.type === EModalEvent.Open)
				)
				.subscribe(({ payload: { ContentComponent, config } }) => {
					setContentComponent(ContentComponent);
					setModalConfig({ ...MODAL_CONFIG, ...config });
					open();
				}),

			modalController$
				.pipe(
					filter(
						(data): data is ModalConfigEventPayload => data.type === EModalEvent.Config
					)
				)
				.subscribe(({ payload }) => {
					setModalConfig((prev) => ({ ...prev, ...payload }));
				}),

			modalController$
				.pipe(
					filter(
						(data): data is CloseModalEventPayload => data.type === EModalEvent.Close
					)
				)
				.subscribe(({ payload }) => closeModal(payload?.force)),

			modalController$
				.pipe(
					filter(
						(data): data is ModalAlertConfigEventPayload =>
							data.type === EModalEvent.Alert
					)
				)
				.subscribe(({ payload }) => setAlert(payload))
		];

		return () => {
			subs.forEach((s) => s?.unsubscribe());
		};
		/**
		 * Removed closeModal from the dependencies of the effect because, on the callback above,
		 * the modalConfig changes which causes this effect to unmount and mount again. This will cause the alert
		 * streams to be ignored.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, close]);

	return (
		<Modal
			isOpen={isOpen}
			headerTitle={modalConfig.title}
			blockAlert={modalConfig.blockAlert}
			showOverlay={modalConfig.showOverlay}
			showCloseButton={modalConfig.showCloseButton}
			customClass={modalConfig.customClass}
			alertConfig={alertConf}
			onClickClose={() => closeModal()}>
			{Content}
		</Modal>
	);
};
