import { useCallback, useEffect, useState } from 'react';

import { createPortal } from 'react-dom';
import { filter } from 'rxjs/operators';

import { Banner, BannerProps } from '../Banner/Banner';

import { DEFAULT_BANNER_ROOT_ID, DEFAULT_INITIAL_BANNER, bannerController$ } from './config';

import { BannerClosePayload, BannerOpenPayload, EBannerEvent } from './types';

interface BannerContainerProps extends BannerProps {
	isOpen: boolean;
	rootId?: string;
}
export function BannerContainer({
	rootId = DEFAULT_BANNER_ROOT_ID,
	isOpen,
	...bannerProps
}: BannerContainerProps) {
	if (!isOpen) {
		return null;
	}

	return createPortal(<Banner {...bannerProps} />, document.getElementById(rootId) as Element);
}

export const BannerObserver = () => {
	const [config, setConfig] = useState<BannerProps>(DEFAULT_INITIAL_BANNER);
	const [isOpen, setOpen] = useState(false);

	const openBanner = useCallback((newConfig: BannerProps) => {
		setOpen(true);
		setConfig(newConfig);
	}, []);

	const closeBanner = useCallback(() => {
		setOpen(false);
		setConfig(DEFAULT_INITIAL_BANNER);
	}, []);

	useEffect(() => {
		const subs = [
			bannerController$
				.pipe(filter((data): data is BannerOpenPayload => data.type === EBannerEvent.Open))
				.subscribe(({ payload }) => openBanner(payload)),

			bannerController$
				.pipe(
					filter((data): data is BannerClosePayload => data.type === EBannerEvent.Close)
				)
				.subscribe(() => closeBanner())
		];

		return () => {
			subs.forEach((s) => s?.unsubscribe());
		};
	}, [closeBanner, openBanner]);

	return <BannerContainer {...config} isOpen={isOpen} rootId={DEFAULT_BANNER_ROOT_ID} />;
};

export const openBanner = (bannerPayload: BannerProps) => {
	bannerController$.next({
		type: EBannerEvent.Open,
		payload: bannerPayload
	});
};

export const closeBanner = () => {
	bannerController$.next({
		type: EBannerEvent.Close
	});
};
