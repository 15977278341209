import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import Joyride from 'react-joyride';

import { DEFAULT_PROPS, DEFAULT_STYLES } from './config';
import { IAppTourConfig, IAppTourStep } from './types';
import { buildJoyRideBaseProps, buildJoyRideSteps } from './utils';

export type AppTourProps = {
	steps: IAppTourStep[];
	isTourRunning: boolean;
	currentStep?: number;
	defaultTourConfig?: IAppTourConfig;
};

export const AppTour = ({
	steps,
	currentStep = 0,
	defaultTourConfig,
	isTourRunning
}: AppTourProps) => {
	const joyRideSteps = useMemo(() => buildJoyRideSteps(steps), [steps]);
	const joyDefaultConfig = useMemo(
		() => (!isEmpty(defaultTourConfig) ? buildJoyRideBaseProps(defaultTourConfig) : {}),
		[defaultTourConfig]
	);

	return (
		<Joyride
			steps={joyRideSteps}
			run={isTourRunning}
			stepIndex={currentStep}
			styles={DEFAULT_STYLES}
			{...DEFAULT_PROPS}
			{...joyDefaultConfig}
		/>
	);
};
