import { EComponentSize, KvActionButtonText, KvAlert } from '@kelvininc/react-ui-components';

import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { isEmpty } from 'lodash-es';
import { ComponentProps } from 'react';

import {
	BANNER_SLIDE_DOWN_ANIMATION_TRANSITION,
	BANNER_SLIDE_DOWN_ANIMATION_VARIANTS
} from './config';
import styles from './styles.module.scss';

export type BannerProps = {
	alertConfig?: ComponentProps<typeof KvAlert>;
	actionsConfig?: ComponentProps<typeof KvActionButtonText>[];
	customClasses?: ClassNamesProp;
};

export const Banner = ({ alertConfig, actionsConfig, customClasses }: BannerProps) => {
	return (
		<motion.div
			initial="enter"
			animate="open"
			exit="exit"
			transition={BANNER_SLIDE_DOWN_ANIMATION_TRANSITION}
			variants={BANNER_SLIDE_DOWN_ANIMATION_VARIANTS}
			className={classNames(styles.BannerContainer, customClasses)}>
			<div className={styles.Alert}>
				{alertConfig && (
					<KvAlert size={EComponentSize.Large} showIcon={false} {...alertConfig}>
						<div className={styles.Actions} slot="actions">
							{!isEmpty(actionsConfig) &&
								actionsConfig?.map((actionConfig, index) => (
									<KvActionButtonText
										key={index}
										{...actionConfig}
										size={EComponentSize.Small}
									/>
								))}
						</div>
					</KvAlert>
				)}
			</div>
		</motion.div>
	);
};
