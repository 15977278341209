import { BaseProps, Styles } from 'react-joyride';

export const DEFAULT_STYLES: Partial<Styles> = {
	options: {
		width: 'unset', // unset width to respect custom content
		backgroundColor: 'transparent',
		arrowColor: 'transparent' // color of the arrow which points to the target element
	},
	tooltip: {
		padding: 0 // disable padding inside tooltip to respect custom content styling
	},
	tooltipContent: {
		padding: 0 // disable padding inside tooltip to respect custom content styling
	}
};

export const DEFAULT_PROPS: BaseProps = {
	spotlightPadding: 8, // padding around spotlight which surrounds the target element
	disableCloseOnEsc: true, // disable close on esc key
	hideBackButton: true, // hide back button from tooltip
	hideCloseButton: true, // hide close button from tooltip,
	disableScrolling: true // disable scrolling when tooltip is open
};
