import { IAppTour } from './AppTour';

export enum EAppTourEvent {
	Open = 'open',
	Close = 'close',
	GoToStep = 'goToStep'
}

export type OpenAppTourEventPayload = {
	type: EAppTourEvent.Open;
	payload: IAppTour;
};

export type CloseAppTourEventPayload = {
	type: EAppTourEvent.Close;
	payload?: IAppTour;
};

export type GoToStepAppTourEventPayload = {
	type: EAppTourEvent.GoToStep;
	payload: number;
};

export type AppTourEvents =
	| OpenAppTourEventPayload
	| CloseAppTourEventPayload
	| GoToStepAppTourEventPayload;
