import { PropsWithChildren } from 'react';

import { AppTourObserver } from '../AppTourObserver';
import { BannerObserver } from '../BannerObserver';
import { ModalObserver } from '../Modal';
import { ToasterObserver } from '../ToasterObserver';

export const PortalsContainer = ({ children }: PropsWithChildren<{}>) => {
	return (
		<>
			<AppTourObserver />
			<BannerObserver />
			<div id="toasters-root"></div>
			<ToasterObserver />
			<div id="modal-root"></div>
			<ModalObserver />
			{children}
		</>
	);
};
