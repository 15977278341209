'use client';

import { useKelvinSDKConfig, useKelvinUiComponentsConfig } from '@kelvininc/nextjs-utils/client';
import { useAtomValue } from 'jotai';
import { Session } from 'next-auth';
import { PropsWithChildren } from 'react';

import { appSettingsAtom, sessionAtom } from '@/src/state';

export const KelvinSDKInitializer = ({ children }: PropsWithChildren<{}>) => {
	const appSettings = useAtomValue(appSettingsAtom);
	const session = useAtomValue(sessionAtom) as Session;

	useKelvinSDKConfig({
		appSettings,
		session
	});

	useKelvinUiComponentsConfig({
		basePath: appSettings.basePath,
		symbolsFileName: appSettings.symbolsFileName
	});

	return <>{children}</>;
};
