import { BannerProps } from '../Banner';

export enum EBannerEvent {
	Open = 0,
	Close
}

export type BannerOpenPayload = {
	type: EBannerEvent.Open;
	payload: BannerProps;
};

export type BannerClosePayload = {
	type: EBannerEvent.Close;
};

export type BannerEventPayload = BannerOpenPayload | BannerClosePayload;
