import { EComponentSize, KvAlert, ModalOverlay } from '@kelvininc/react-ui-components';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './styles.module.scss';
import { IModalAlert } from './types';

interface IModalProps {
	headerTitle?: string;
	isOpen: boolean;
	showOverlay?: boolean;
	showCloseButton?: boolean;
	blockAlert?: boolean;
	alertConfig?: IModalAlert;
	customClass?: ClassNamesProp;
	onClickClose: () => void;
}

export const Modal = ({
	showOverlay = false,
	blockAlert = false,
	alertConfig,
	children,
	...modalProps
}: PropsWithChildren<IModalProps>) => {
	return (
		<ModalOverlay
			{...modalProps}
			customClass={classNames(modalProps.customClass, styles.Modal)}>
			<div slot="body" className={styles.ModalContainer}>
				<div
					className={classNames(styles.Alert, {
						[styles.Opened]: alertConfig,
						[styles.Blocked]: blockAlert
					})}>
					{alertConfig && (
						<KvAlert
							label={alertConfig.label}
							description={alertConfig.description}
							type={alertConfig.type}
							size={alertConfig.size}>
							{alertConfig.descriptionSlot && (
								<div slot="description">{alertConfig.descriptionSlot}</div>
							)}
							<div
								slot="actions"
								data-test-id="e2e-alert-actions"
								className={classNames(styles.AlertActions, {
									[styles.Small]: alertConfig.size === EComponentSize.Small
								})}>
								{alertConfig.actions}
							</div>
						</KvAlert>
					)}
				</div>
				<div className={styles.ContentWrapper}>
					{showOverlay && <div className={styles.Overlay}></div>}
					{children}
				</div>
			</div>
		</ModalOverlay>
	);
};
