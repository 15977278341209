import { useCallback, useEffect, useState } from 'react';

import { filter } from 'rxjs/operators';

import { AppTour, IAppTour } from './AppTour';

import { appTourController$ } from './config';
import {
	CloseAppTourEventPayload,
	EAppTourEvent,
	GoToStepAppTourEventPayload,
	OpenAppTourEventPayload
} from './types';

export const AppTourObserver = () => {
	const [tourPayload, setTourPayload] = useState<IAppTour | null>(null);
	const [isTourRunning, setIsTourRunning] = useState(false);

	const closeTour = useCallback(() => {
		setTourPayload(null);
		setIsTourRunning(false);
	}, []);

	useEffect(() => {
		const subs = [
			appTourController$
				.pipe(
					filter(
						(data): data is OpenAppTourEventPayload => data.type === EAppTourEvent.Open
					)
				)
				.subscribe(({ payload }) => {
					setTourPayload(payload);
					setIsTourRunning(true);
				}),

			appTourController$
				.pipe(
					filter(
						(data): data is CloseAppTourEventPayload =>
							data.type === EAppTourEvent.Close
					)
				)
				.subscribe(() => {
					closeTour();
				}),

			appTourController$
				.pipe(
					filter(
						(data): data is GoToStepAppTourEventPayload =>
							data.type === EAppTourEvent.GoToStep
					)
				)
				.subscribe(({ payload }) => {
					if (tourPayload) {
						setIsTourRunning(false);
						setTimeout(() => {
							setIsTourRunning(true);
							setTourPayload({ ...tourPayload, currentStep: payload });
						}, 500);
					}
				})
		];

		return () => {
			subs.forEach((s) => s?.unsubscribe());
		};
	}, [closeTour, tourPayload]);

	if (!tourPayload) return null;

	return <AppTour {...tourPayload} isTourRunning={isTourRunning} />;
};

export const openAppTour = (payload: IAppTour) =>
	appTourController$.next({ type: EAppTourEvent.Open, payload });

export const closeAppTour = () => appTourController$.next({ type: EAppTourEvent.Close });

export const goToTourStep = (payload: number) =>
	appTourController$.next({ type: EAppTourEvent.GoToStep, payload });
