import { isNil, omitBy } from 'lodash-es';
import { BaseProps, Step } from 'react-joyride';

import { IAppTourConfig, IAppTourStep } from './types';

export const buildJoyRideSteps = (steps: IAppTourStep[]): Step[] => {
	return steps.map(
		(step) =>
			({
				content: step.content,
				target: step.target,
				disableBeacon: true,
				disableCloseOnEsc: true,
				hideFooter: true,
				offset: step.offset ?? 0,
				placement: step.placement ?? 'auto',
				...buildJoyRideBaseProps(step)
			}) as Step
	);
};

export const buildJoyRideBaseProps = (props: IAppTourConfig): BaseProps =>
	omitBy(
		{
			spotlightPadding: props.spotlightPadding,
			spotlightClicks: props.allowTargetElementClick,
			disableOverlay: props.hideOverlay,
			disableOverlayClose: props.disableOverlayClicks
		},
		isNil
	);
