import { Transition, Variants } from 'framer-motion';

export const BANNER_SLIDE_DOWN_ANIMATION_VARIANTS: Variants = {
	enter: { height: 0 },
	open: { height: 'auto' },
	exit: { height: 0 }
};

export const BANNER_SLIDE_DOWN_ANIMATION_TRANSITION: Transition = {
	ease: 'easeInOut',
	duration: 0.7
};
