'use client';

import { HeapAnalyticsWrapper, useSessionProvider } from '@kelvininc/nextjs-utils/client';
import { UserMeGet } from '@kelvininc/node-client-sdk';
import { KvLoader } from '@kelvininc/react-ui-components';
import { PortalsContainer, SuspenseFallback } from '@kelvininc/shared-ui';
import dynamic from 'next/dynamic';
import { ComponentType, FC, PropsWithChildren } from 'react';

import { useSessionRenewedListener } from '../hooks';
import { useUnauthorizedErrorListener } from '../hooks/useUnauthorizedErrorListener';

import { FirstExperienceProvider } from './FirstExperienceProvider';
import { KelvinSDKInitializer } from './KelvinSDK';

type AuthenticatedProvidersProps = {
	user: UserMeGet;
};

const CoreNavigationSidebarProvider: ComponentType<PropsWithChildren<{}>> = dynamic(
	() =>
		import(
			'../components/client/CoreNavigationSidebar/providers/CoreNavigationSidebarProvider'
		).then((m) => m.CoreNavigationSidebarProvider),
	{ ssr: false, loading: () => <KvLoader isLoading /> }
);

export const AuthenticatedProviders: FC<PropsWithChildren<AuthenticatedProvidersProps>> = ({
	children,
	user
}) => {
	const { isSigningOut } = useSessionProvider();

	useUnauthorizedErrorListener();
	useSessionRenewedListener();

	if (isSigningOut) {
		return <SuspenseFallback />;
	}

	return (
		<PortalsContainer>
			<HeapAnalyticsWrapper user={user}>
				<KelvinSDKInitializer>
					<CoreNavigationSidebarProvider>
						<FirstExperienceProvider>
							<div id="sidepanel-root"></div>
							<div id="navigation-sidebar-root"></div>
							{children}
						</FirstExperienceProvider>
					</CoreNavigationSidebarProvider>
				</KelvinSDKInitializer>
			</HeapAnalyticsWrapper>
		</PortalsContainer>
	);
};
